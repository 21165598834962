@import '@aurora/shared-client/styles/_variables.pcss';

.lia-g-td-snap {
  white-space: nowrap;

  @media (--lia-breakpoint-up-md) {
    width: 1%;
  }
}

.lia-g-tr-hover {
  &:hover td {
    a:not(.dropdown-item),
    button:not(.dropdown-item) {
      text-decoration: var(--lia-bs-link-hover-decoration) !important;
    }
  }
  td {
    a:not(.dropdown-item),
    button:not(.dropdown-item) {
      color: var(--lia-bs-gray-900) !important;

      &:hover {
        color: var(--lia-bs-link-hover-color) !important;
        text-decoration: var(--lia-bs-link-hover-decoration) !important;
      }
    }
  }
}

.lia-g-table-list {
  border-collapse: separate;
  border-spacing: 0;
  background-color: var(--lia-bs-white);

  thead th {
    padding: 10px;
    vertical-align: middle;
    border: 0;
    font-size: var(--lia-bs-font-size-sm);
    font-weight: var(--lia-font-weight-md);
    color: var(--lia-bs-headings-font-weight);
  }

  tbody td {
    padding: 15px 10px;
    vertical-align: middle;
    font-size: var(--lia-bs-font-size-sm);
  }
}
