@import '@aurora/shared-client/styles/_variables.pcss';

.lia-icon {
  display: inline-block;
  fill: var(--lia-local-icon-color);

  & > image {
    height: 100%;
    width: 100%;
  }
}

.lia-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--lia-bs-border-radius-sm);
  background-color: hsla(
    var(--lia-local-icon-color-h),
    var(--lia-local-icon-color-s),
    var(--lia-local-icon-color-l),
    0.1
  );

  &.lia-size {
    @each $size in (10, 12, 14, 16, 20, 24, 30, 40, 50, 60) {
      &-$size .lia-icon {
        height: 50%;
        width: 50%;
      }
    }
    @each $size in (80, 120, 160) {
      &-$size .lia-icon {
        height: 40%;
        width: 40%;
      }
    }
  }
}
