@import '@aurora/shared-client/styles/mixins/_panel.pcss';
@import '@aurora/shared-client/styles/_variables.pcss';

.lia-g-message-box {
  padding: 25px 15px;
  @include panel-defaults();

  @media (--lia-breakpoint-up-md) {
    padding: 20px 25px;
  }
  @media (--lia-breakpoint-down-xs) {
    border-radius: 0;
  }
}

.lia-g-page-full-height {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.lia-g-popper-right {
  right: 0 !important;
  left: auto !important;
}

.lia-g-pseudo-body {
  font-family: var(--lia-bs-font-family-base);
  font-size: var(--lia-bs-font-size-base);
  font-weight: var(--lia-bs-font-weight-base);
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-body-bg);

  &-inner {
    margin: var(--lia-body-margin);
    max-width: var(--lia-body-max-width);
    background-color: var(--lia-bs-body-bg);
  }
}

.lia-g-admin-width {
  &,
  body {
    min-width: 1110px;
  }
}

.lia-g-theme-styleguide-box {
  padding: 25px;
  box-shadow: var(--lia-bs-box-shadow-sm);
  border: 1px solid var(--lia-bs-border-color);
  background-color: var(--lia-bs-white);
  border-radius: var(--lia-bs-border-radius);
}

/* Adds vertical spacing between section widgets in the same column */
.lia-g-section-widget:not(:last-of-type):not(:empty) {
  margin-bottom: 30px;
}

.lia-g-admin-sections-wrap {
  display: flex;
  flex-direction: column;
  margin-left: 280px;
}

.lia-g-admin-scroll-wrap {
  display: grid;
  grid-template-columns: 250px 1fr;
  column-gap: 30px;

  .lia-g-admin-scroll-nav {
    position: sticky;
    top: calc(($lia-admin-navbar-height * 2) + 40px);

    &.lia-g-admin-has-solo-nav {
      top: calc($lia-admin-navbar-height + 40px);
    }
  }
  .lia-g-admin-scroll-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 100%;
    row-gap: 30px;
  }
}

.lia-g-admin-panel {
  padding: 20px 30px 30px;
  background-color: var(--lia-panel-bg-color);
  border-radius: var(--lia-panel-border-radius);
}
