/**
  Taken from https://gist.github.com/johanlef/518a511b2b2f6b96c4f429b3af2f169a
 */
/* Gray Scale */
/* Main Colors */
/* Link Color */
/* Body Background */
/* Gutters */
/* Font Family */
/* Font Weight */
/* Line Height */
/* Font Size */
/* Headings */
/* Header Font Size */
/* Lead */
/* Text Muted */
/* Blockquote Font Size */
/* Border */
/* Box Shadows */
/* List Group */
/* Grids Breakpoints */
/* Container Max Widths */
/* Modals */
/* Popover */
/* Inputs */
/* Button Sizes */
/* Button Other */
/* Toasts */
/* Dropdowns */
/* Pagination */
/* Card */
/* List Groups */
/* Nav */
/* Progress Bars */
/* Close */
/* Table */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: var(--lia-bs-white);
  --gray: var(--lia-bs-gray-600);
  --gray-dark: var(--lia-bs-gray-800);
  --primary: var(--lia-bs-primary);
  --secondary: var(--lia-bs-secondary);
  --success: var(--lia-bs-success);
  --info: var(--lia-bs-info);
  --warning: var(--lia-bs-warning);
  --danger: var(--lia-bs-danger);
  --light: var(--lia-bs-light);
  --dark: var(--lia-bs-dark);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1260px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: hsla(
    var(--lia-bs-black-h),
    var(--lia-bs-black-s),
    var(--lia-bs-black-l),
    0
  );
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: var(--lia-bs-font-family-base);
  font-size: var(--lia-bs-font-size-base);
  font-weight: var(--lia-bs-font-weight-base);
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-bs-body-color);
  text-align: left;
  background-color: var(--lia-bs-body-bg);
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: var(--lia-bs-font-weight-bold);
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--lia-bs-link-color);
  text-decoration: var(--lia-bs-link-decoration);
  background-color: transparent;
}
a:hover {
  color: var(--lia-bs-link-hover-color);
  text-decoration: var(--lia-bs-link-hover-decoration);
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--lia-bs-text-muted);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: var(--lia-bs-label-margin-bottom);
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: var(--lia-bs-headings-font-family);
  font-weight: var(--lia-bs-headings-font-weight);
  line-height: var(--lia-bs-headings-line-height);
  font-style: var(--lia-headings-font-style);
  color: var(--lia-bs-headings-color);
}

h1 {
  font-size: var(--lia-bs-h1-font-size);
  letter-spacing: var(--lia-h1-letter-spacing);
  font-weight: var(--lia-h1-font-weight);
}

h2 {
  font-size: var(--lia-bs-h2-font-size);
  letter-spacing: var(--lia-h2-letter-spacing);
  font-weight: var(--lia-h2-font-weight);
}

h3 {
  font-size: var(--lia-bs-h3-font-size);
  letter-spacing: var(--lia-h3-letter-spacing);
  font-weight: var(--lia-h3-font-weight);
}

h4 {
  font-size: var(--lia-bs-h4-font-size);
  letter-spacing: var(--lia-h4-letter-spacing);
  font-weight: var(--lia-h4-font-weight);
}

h5 {
  font-size: var(--lia-bs-h5-font-size);
  letter-spacing: var(--lia-h5-letter-spacing);
  font-weight: var(--lia-h5-font-weight);
}

h6 {
  font-size: var(--lia-bs-h6-font-size);
  letter-spacing: var(--lia-h6-letter-spacing);
  font-weight: var(--lia-h6-font-weight);
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid
    hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.1);
}

small {
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-bs-font-weight-base);
  letter-spacing: var(--lia-letter-spacing-sm);
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: var(--lia-bs-white);
  background-color: var(--lia-bs-gray-900);
  border-radius: var(--lia-bs-border-radius-sm);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: var(--lia-bs-font-weight-bold);
}

pre {
  display: block;
  font-size: 87.5%;
  color: var(--lia-bs-gray-900);
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid var(--lia-bs-gray-500);
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

body {
  font-style: var(--lia-font-style-base);
  -moz-osx-font-smoothing: grayscale;
}

a,
button {
  touch-action: manipulation;
}
