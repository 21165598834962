.lia-maintenance-message {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin-left: 10px;
  font-size: var(--lia-bs-font-size-sm);
}
.lia-maintenance-icon {
  display: flex;
  position: relative;
  padding: 5px;
  justify-content: center;
  align-items: center;
}
.lia-danger-icon {
  display: inline-block;
  margin-top: -2px;
}
.lia-pulse:after {
  position: absolute;
  content: '';
  top: 0;
  display: block;
  border-radius: var(--lia-avatar-border-radius);
  height: 100%;
  width: 100%;
  animation: lia-switch-pulse 2s infinite;
}

@keyframes lia-switch-pulse {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 0 var(--lia-bs-primary);
  }
  70% {
    box-shadow: 0 0 0 10px transparent;
  }
  90% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    opacity: 0;
  }
}
