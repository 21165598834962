.lia-g-clamp {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  &-1 {
    -webkit-line-clamp: 1;
  }

  &-2 {
    -webkit-line-clamp: 2;
  }

  &-3 {
    -webkit-line-clamp: 3;
  }

  &-4 {
    -webkit-line-clamp: 4;
  }

  &-5 {
    -webkit-line-clamp: 5;
  }

  &-6 {
    -webkit-line-clamp: 6;
  }

  &-7 {
    -webkit-line-clamp: 7;
  }

  &-8 {
    -webkit-line-clamp: 8;
  }

  &-9 {
    -webkit-line-clamp: 9;
  }

  &-10 {
    -webkit-line-clamp: 10;
  }

  &-11 {
    -webkit-line-clamp: 11;
  }

  &-12 {
    -webkit-line-clamp: 12;
  }

  &-13 {
    -webkit-line-clamp: 13;
  }

  &-14 {
    -webkit-line-clamp: 14;
  }

  &-15 {
    -webkit-line-clamp: 15;
  }

  &-16 {
    -webkit-line-clamp: 16;
  }

  &-17 {
    -webkit-line-clamp: 17;
  }

  &-18 {
    -webkit-line-clamp: 18;
  }

  &-19 {
    -webkit-line-clamp: 19;
  }

  &-20 {
    -webkit-line-clamp: 20;
  }
}
