.lia-box {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 25px;
  left: 25px;
  padding: 20px;
  max-width: fit-content;
  background-color: var(--lia-bs-white);
  border: 1px solid var(--lia-bs-border-color);
  border-radius: var(--lia-bs-border-radius);
  box-shadow: var(--lia-bs-box-shadow);
  color: var(--lia-bs-body-color);
  z-index: 1200;
}

.lia-dropdown-item {
  white-space: break-spaces;
}
