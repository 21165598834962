.lia-g-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  word-break: break-word;

  .lia-g-card-header.lia-g-card-header {
    margin-bottom: 25px;
    width: 100%;
  }

  .lia-g-card-body.lia-g-card-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 20px;
    color: var(--lia-bs-body-color);
    font-size: var(--lia-bs-font-size-base);
  }

  .lia-g-card-body-is-clickable {
    position: relative;
    cursor: pointer;
  }

  .lia-g-card-item:not(:last-child) {
    margin-bottom: 10px;
  }

  .lia-g-card-avatar {
    border-radius: var(--lia-bs-border-radius);
    margin-bottom: 20px;

    &.lia-is-circle {
      border-radius: var(--lia-border-radius-50);
    }
  }

  .lia-g-card-title {
    display: block;
    color: var(--lia-bs-body-color);
    font-size: var(--lia-bs-font-size-lg);
    font-weight: var(--lia-font-weight-md);

    &:hover {
      color: var(--lia-bs-body-color);
      text-decoration: underline;
    }
  }

  .lia-g-card-subtitle {
    color: var(--lia-bs-body-color);
    font-size: var(--lia-bs-font-size-base);
    margin-bottom: 0;
  }

  .lia-g-card-meta {
    display: block;
    color: var(--lia-bs-text-muted);
    font-size: var(--lia-bs-font-size-sm);
    white-space: normal;
  }

  .lia-g-card-count {
    color: var(--lia-bs-gray-700);
  }

  .lia-g-card-footer.lia-g-card-footer {
    display: flex;
    justify-content: center;
    margin-top: auto;
    width: 100%;
    color: var(--lia-bs-gray-700);

    .lia-g-card-count:not(:first-child) {
      margin-left: 20px;
    }
  }

  .lia-g-card-footer-unstyled.lia-g-card-footer-unstyled {
    border: none;
    padding-top: 0;
    margin-top: 0;
  }

  &.lia-g-card-sm,
  &.lia-g-card-xs {
    .lia-g-card-title {
      font-size: var(--lia-bs-font-size-base);
    }

    .lia-g-card-subtitle {
      font-size: var(--lia-bs-font-size-sm);
    }
  }

  &.lia-g-card-xs {
    .lia-g-card-item:not(:last-child) {
      margin-bottom: 5px;
    }
    .lia-g-card-avatar {
      margin-bottom: 15px;
    }
  }

  .lia-g-card-left {
    align-items: flex-start !important;
    justify-content: flex-start !important;
    text-align: left;
  }

  .lia-g-card-center {
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .lia-g-card-right {
    align-items: flex-end !important;
    text-align: right;
    justify-content: flex-end !important;
  }
}

.lia-g-hovercard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 320px;
  background-color: var(--lia-bs-white);
  box-shadow: var(--lia-bs-box-shadow);
  border-radius: var(--lia-bs-border-radius);
  border: 1px solid var(--lia-bs-border-color);
  z-index: 1050;
}

.lia-g-hovercard-link {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.lia-g-hovercard-icon {
  margin-bottom: 16px;
}

.lia-g-hovercard-title {
  font-weight: var(--lia-bs-font-weight-bold);
  color: var(--lia-bs-body-color);
  margin-bottom: 0;

  a&:hover,
  a&:focus {
    color: var(--lia-bs-body-color);
    text-decoration: none;
  }
}

.lia-g-hovercard-info {
  display: block;
  font-size: var(--lia-bs-font-size-sm);
  margin-top: 14px;
  text-align: left;
}

.lia-g-hovercard-meta-text {
  display: block;
  font-size: var(--lia-font-size-xs);
  color: var(--lia-bs-text-muted);
}

.lia-g-hovercard-divided-text {
  display: flex;
  align-items: center;
}

.lia-g-hovercard-badges {
  width: 100%;
  margin-top: 20px;
  justify-content: center;

  + .lia-g-hovercard-data {
    margin-top: 18px;
  }
}

.lia-g-hovercard-data {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 12px 10px 0;

  &-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    min-width: 25%;
  }

  &-number {
    font-weight: var(--lia-bs-font-weight-bold);
    color: var(--lia-bs-gray-900);
    margin: 0;
  }

  &-title {
    font-weight: var(--lia-bs-font-weight-normal);
    font-size: var(--lia-font-size-xs);
    color: var(--lia-bs-gray-700);
  }
}

.lia-g-hovercard-btn {
  margin-top: 20px;
  width: 100%;
}
