@font-face {
  font-family: 'Atkinson Hyperlegible';
  src: url('/static/fonts/Atkinson-Hyperlegible-Font-Print-and-Web-2020-0514/Web Fonts/WOFF/Atkinson-Hyperlegible-Regular-102.woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Atkinson Hyperlegible';
  src: url('/static/fonts/Atkinson-Hyperlegible-Font-Print-and-Web-2020-0514/Web Fonts/WOFF/Atkinson-Hyperlegible-Bold-102.woff');
  font-weight: bold;
}
