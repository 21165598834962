.lia-load-size {
  min-height: calc(var(--lia-bs-line-height-base) * var(--lia-bs-font-size-sm));

  &-sm {
    min-height: calc(1.2 * var(--lia-font-size-xs));
  }
  &-lg {
    min-height: calc(var(--lia-bs-line-height-base) * var(--lia-bs-font-size-base));
  }

  .lia-loading-color {
    background-color: currentColor;
    opacity: 0.75;
  }
}
