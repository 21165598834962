.lia-password-wrap {
  position: relative;

  /** In addition to !important we also need a double selector here to ensure we win the specificity war no matter what order sheets are loaded in */
  .lia-input-field.lia-input-field {
    /** Disclaimer: !important is bad and wrong, but it is better than potentially breaking things by removing it from _forms.scss */
    padding-right: 38px !important;
  }

  .lia-show-password {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 38px;
    cursor: pointer;
    user-select: none;
  }
}

.lia-input-field::-ms-reveal {
  display: none;
}
