@mixin createDeclaration($type, $dir, $value) {
  @if $type == margin {
    @if $dir == top {
      /* prettier-ignore */
      margin-top: $(value)px !important;
    }
    @if $dir == right {
      /* prettier-ignore */
      margin-right: $(value)px !important;
    }
    @if $dir == bottom {
      /* prettier-ignore */
      margin-bottom: $(value)px !important;
    }
    @if $dir == left {
      /* prettier-ignore */
      margin-left: $(value)px !important;
    }
    @if $dir == {
      /* prettier-ignore */
      margin: $(value)px !important;
    }
  }
  @if $type == padding {
    @if $dir == top {
      /* prettier-ignore */
      padding-top: $(value)px !important;
    }
    @if $dir == right {
      /* prettier-ignore */
      padding-right: $(value)px !important;
    }
    @if $dir == bottom {
      /* prettier-ignore */
      padding-bottom: $(value)px !important;
    }
    @if $dir == left {
      /* prettier-ignore */
      padding-left: $(value)px !important;
    }
    @if $dir == {
      /* prettier-ignore */
      padding: $(value)px !important;
    }
  }
}

@mixin createRules($abbrev, $type, $infix, $size) {
  .lia-g-$(abbrev)$(infix)-$(size) {
    @include createDeclaration($type, , $size);
  }
  .lia-g-$(abbrev)t$(infix)-$(size),
  .lia-g-$(abbrev)y$(infix)-$(size) {
    @include createDeclaration($type, top, $size);
  }
  .lia-g-$(abbrev)r$(infix)-$(size),
  .lia-g-$(abbrev)x$(infix)-$(size) {
    @include createDeclaration($type, right, $size);
  }
  .lia-g-$(abbrev)b$(infix)-$(size),
  .lia-g-$(abbrev)y$(infix)-$(size) {
    @include createDeclaration($type, bottom, $size);
  }
  .lia-g-$(abbrev)l$(infix)-$(size),
  .lia-g-$(abbrev)x$(infix)-$(size) {
    @include createDeclaration($type, left, $size);
  }
}

@mixin createSpacingRulesForBreakpoint($breakpoint) {
  @each $size in (0, 5, 10, 15, 20, 25, 30, 35, 40, 50, 100) {
    @each $type in (margin, padding) {
      @if $type == margin {
        @include createRules(m, $type, $breakpoint, $size);
      } @else {
        @include createRules(p, $type, $breakpoint, $size);
      }
    }
  }
}
