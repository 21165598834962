.lia-loading-bar-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(var(--lia-font-size-xs) * 1.4);
}

.lia-loading-bar {
  overflow: hidden;
  height: 6px;
  box-shadow: var(--lia-box-shadow-xs);
  border-radius: var(--lia-bs-border-radius-sm);

  &-light {
    background-color: var(--lia-load-bar-light-color);
  }

  &-dark {
    background-color: var(--lia-load-bar-dark-color);
  }

  &-light {
    > [role='progressbar'] {
      height: inherit;
      background-color: var(--lia-bs-white);
      background-size: 12px 12px;
      transition: var(--lia-timing-normal) linear;
    }
  }

  &-dark {
    > [role='progressbar'] {
      height: inherit;
      background-color: var(--lia-bs-gray-700);
      background-size: 12px 12px;
      transition: var(--lia-timing-normal) linear;
    }
  }
}
