@import '@aurora/shared-client/styles/_variables.pcss';

.lia-g-locked-modal {
  .lia-g-locked-modal-dialog,
  .lia-g-locked-modal-content {
    max-height: calc(100dvh - 58px);
  }

  .lia-g-locked-modal-content {
    position: relative;
  }

  .lia-g-locked-modal-header {
    position: relative;
    border-bottom: 1px solid var(--lia-bs-border-color);
    z-index: 2;
  }

  .lia-g-locked-modal-body {
    position: static;
    overflow-y: auto;
    overflow-x: clip;
    padding-bottom: 0;
    margin-top: -1px;

    &:before {
      position: relative;
      display: block;
      content: '';
      height: 1px;
      transform: translateX(-30px);
      width: calc(100% + 60px);
      background-color: var(--lia-bs-white);
      z-index: 10;
      top: 0;
    }
  }

  .lia-g-locked-modal-footer {
    position: sticky;
    bottom: 0;
    margin: 20px -30px 0;
    padding: 20px 30px 30px;
    background-color: var(--lia-bs-white);
    border-top: 1px solid var(--lia-bs-border-color);
    border-radius: 0 0 var(--lia-bs-border-radius-lg) var(--lia-bs-border-radius-lg);
    z-index: 12;
  }

  @media (--lia-breakpoint-down-sm) {
    .lia-g-locked-modal-body:before {
      transform: translateX(-20px);
      width: calc(100% + 40px);
    }
    .lia-g-locked-modal-footer {
      padding: 10px 20px 20px;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

.lia-g-modal-buttons-wrap {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  column-gap: 10px;
}

/* Adds control for dictating the scrollable <Modal.Body> overflow, which otherwise could expand to view port sizes */
.lia-g-infinite-scroll-modal {
  max-height: 800px;
}

.lia-g-subtext-modal-header {
  display: grid;
  grid-template-areas:
    'title close'
    'subtitle subtitle';
  grid-template-columns: 1fr auto;

  > [type='button'] {
    grid-area: close;
  }
}

.lia-g-subtext-modal-title {
  grid-area: title;
}

.lia-g-subtext-modal-subtext {
  grid-area: subtitle;
  margin-top: 5px;
  font-size: var(--lia-bs-font-size-sm);
}
