.lia-g-navbar-link {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  min-height: var(--lia-nav-brand-logo-height);
  padding: var(--lia-nav-link-py) var(--lia-nav-link-px);
  color: var(--lia-nav-link-color);
  transition: color var(--lia-timing-normal), background-color var(--lia-timing-normal),
    border-color var(--lia-timing-normal), box-shadow var(--lia-timing-normal);
  background-color: var(--lia-nav-link-bg-color);
  border: var(--lia-nav-link-border);
  border-radius: var(--lia-nav-link-border-radius);
  box-shadow: var(--lia-nav-link-box-shadow);
  font-size: var(--lia-nav-link-font-size);
  font-weight: var(--lia-nav-link-font-weight);
  font-style: var(--lia-nav-link-font-style);
  text-transform: var(--lia-nav-link-text-transform);
  letter-spacing: var(--lia-nav-link-letter-spacing);
  line-height: 1.428571428571429;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--lia-nav-link-hover-color);
    background-color: var(--lia-nav-link-bg-hover-color);
    border: var(--lia-nav-link-border-hover);
    box-shadow: var(--lia-nav-link-box-shadow-hover);
    outline: none;

    .lia-g-navbar-link-text:after {
      border-bottom: var(--lia-nav-link-text-border-bottom-hover);
    }
    .lia-g-navbar-dropdown-icon {
      fill: var(--lia-nav-link-hover-color);
    }
  }
  &:focus-visible {
    outline: unset !important;
  }
}

.lia-g-navbar-link-text {
  display: inline-block;
  position: relative;
  overflow-wrap: break-word;
  word-break: break-word;

  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    bottom: calc(-1 * var(--lia-nav-link-py));
    border-bottom: var(--lia-nav-link-text-border-bottom);
  }
}

.lia-g-navbar-dropdown-menu {
  min-width: 175px;
  background-color: var(--lia-nav-bg-color);
  padding: var(--lia-nav-dropdown-pt) var(--lia-nav-dropdown-px) var(--lia-nav-dropdown-pb);
  max-height: calc(100dvh - var(--lia-d-navbar-height));
  overflow-y: auto;

  .lia-g-navbar-link {
    padding: var(--lia-nav-link-dropdown-py) var(--lia-nav-link-dropdown-px);
    min-height: unset;

    .lia-g-navbar-link-text:after {
      bottom: calc(-1 * var(--lia-nav-link-dropdown-py));
    }
  }
  @media (--lia-breakpoint-up-lg) {
    margin: 0 calc(-1 * var(--lia-nav-dropdown-px)) !important;
  }
}

.lia-g-navbar-dropdown-divider {
  border-top-color: var(--lia-nav-dropdown-border-color);
  margin: var(--lia-nav-dropdown-divider-mt) calc(-1 * var(--lia-nav-dropdown-px))
    var(--lia-nav-dropdown-divider-mb);
}

.lia-g-navbar-dropdown-icon {
  fill: var(--lia-nav-link-color);
  margin-left: 3px;
  width: var(--lia-nav-link-font-size);
  height: var(--lia-nav-link-font-size);

  @media (--lia-breakpoint-down-md) {
    margin-left: auto;
  }
}
