.lia-g-auth-wrapper {
  align-items: center;
}

.lia-g-auth-panel.lia-g-auth-panel {
  width: 100%;
  margin: 100px auto auto;
  max-width: 450px;

  .lia-g-auth-panel-header {
    padding: 30px 40px 20px;
    text-align: center;
  }
  .lia-g-auth-panel-body {
    padding: 0 40px 40px;
  }

  @media (--lia-breakpoint-down-xs) {
    margin-top: 0;
    max-width: auto;
    border-radius: 0;

    .lia-g-auth-panel-header {
      padding: 20px;
    }
    .lia-g-auth-panel-body {
      padding: 0 20px 20px;
    }
  }
}

.lia-g-auth-nav-section {
  margin-top: 30px;
  font-size: var(--lia-bs-font-size-sm);
  text-align: center;

  + .lia-g-auth-nav-section {
    margin-top: 5px;
  }
}

.lia-g-auth-panel-h1 {
  font-size: var(--lia-bs-h3-font-size);
  line-height: var(--lia-bs-headings-line-height);
  margin-bottom: 0;
}
