.lia-box {
  height: 100%;
  padding: 15px 20px;
  word-break: break-word;
  border: 1px solid var(--lia-alert-system-color);
  background-color: hsl(var(--lia-alert-system-color-h), var(--lia-alert-system-color-s), 98%);
  border-radius: var(--lia-bs-border-radius-lg);
  box-shadow: inset 0 0 0 3px
    hsla(
      var(--lia-alert-system-color-h),
      var(--lia-alert-system-color-s),
      var(--lia-alert-system-color-l),
      0.2
    );
}

.lia-title,
.lia-message {
  display: block;
  color: var(--lia-alert-system-color);
}

.lia-title:not(.lia-is-component) {
  line-height: var(--lia-bs-headings-line-height);
  font-weight: var(--lia-bs-headings-font-weight);
  font-size: var(--lia-bs-h5-font-size);
  letter-spacing: var(--lia-h5-letter-spacing);
}

.lia-message:not(.lia-is-component) {
  font-size: var(--lia-bs-font-size-sm);
}

.lia-title + .lia-message {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 3px solid
    hsla(
      var(--lia-alert-system-color-h),
      var(--lia-alert-system-color-s),
      var(--lia-alert-system-color-l),
      0.2
    );
}
