.lia-g-form-field-no-border {
  > input,
  > textarea {
    border: 0;
    padding: 0;
    border-radius: 0;

    &:focus {
      border: 0;
      box-shadow: none !important;
    }
  }
}

.lia-g-form-field-no-resize > textarea {
  resize: none;
}

.lia-g-validation-error {
  display: flex !important; /* Override Bootstrap */
  color: var(--lia-bs-danger);
  font-size: var(--lia-font-size-xs);
  margin-top: 8px;

  .lia-g-validation-error-icon {
    margin-right: 5px;
    flex-shrink: 0;
  }
}

.lia-g-validation-alert {
  padding: 10px 0 0 15px;

  &-heading {
    font-size: var(--lia-bs-font-size-sm);
    padding-top: 5px;
  }
}

.lia-g-disabled {
  opacity: var(--lia-bs-btn-disabled-opacity);
}

.lia-g-legend-as-label.lia-g-legend-as-label {
  display: inline-block;
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-font-weight-md);
  line-height: var(--lia-bs-line-height-base);
  margin-bottom: 10px;
}

.lia-g-field-divider {
  border-bottom: 1px solid var(--lia-bs-border-color);
  margin-bottom: 20px;
  padding-bottom: 25px;
}

.lia-g-setting-form-field-divider {
  &:not(:last-child) {
    margin-bottom: 15px !important;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--lia-bs-border-color);
  }
}
