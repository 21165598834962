.lia-g-object-fit-cover {
  object-fit: cover;
}

.lia-g-object-fit-contain {
  object-fit: contain;
}

.lia-g-inline-icon-link {
  display: inline-flex;
  align-items: center;

  .lia-g-inline-icon {
    margin-left: 4px;
    fill: var(--lia-bs-link-color);
  }
  &:hover .lia-g-inline-icon {
    fill: var(--lia-bs-link-hover-color);
  }
}

.lia-g-icon-size {
  @each $size in (10, 12, 14, 16, 20, 24, 30, 40, 50, 60, 80, 120, 160) {
    &-$size {
      width: var(--lia-icon-size-#{$size});
      height: var(--lia-icon-size-#{$size});
      min-width: var(--lia-icon-size-#{$size});
      min-height: var(--lia-icon-size-#{$size});
    }
  }
}
