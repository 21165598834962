.lia-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 20px;
}

.lia-svg.lia-svg {
  max-width: 110px;
  max-height: 90px;
  margin-bottom: 5px;
}

.lia-is-left {
  align-items: flex-start;
  text-align: left;
}

.lia-is-center {
  align-items: center;
  text-align: center;
}

.lia-is-right {
  align-items: flex-end;
  text-align: right;
}

.lia-text-center {
  max-width: 300px;
}

.lia-title {
  color: var(--lia-bs-text-muted);
  font-size: var(--lia-bs-h6-font-size);
  line-height: var(--lia-bs-headings-line-height);
  font-weight: var(--lia-bs-font-weight-bold);
}

.lia-sub-title {
  color: var(--lia-bs-text-muted);
}

.lia-title,
.lia-sub-title {
  display: block;
  overflow-wrap: break-word;
  word-break: break-word;
}
