@mixin spoiler-container-margin-styles(
  $margint: --lia-rte-default-message-item-mt,
  $marginb: --lia-rte-default-message-item-mb
) {
  margin-top: var($margint);
  margin-bottom: var($marginb);

  &:first-child,
  [data-mce-caret]:first-child + & {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin spoiler-container-common-styles() {
  display: block;
  background-color: var(--lia-bs-gray-200);
  border-radius: var(--lia-bs-border-radius-sm);
}
