.lia-g-divider {
  + .lia-g-divider {
    margin-left: var(--lia-divider-ml);

    &:before {
      content: '';
      display: inline-block;
      background-color: var(--lia-divider-bg-color);
      margin-right: var(--lia-divider-mr);
      height: var(--lia-divider-size);
      width: var(--lia-divider-size);
      border-radius: var(--lia-divider-border-radius);
      vertical-align: middle;
    }
  }
  &.lia-g-is-color-mimic + .lia-g-is-color-mimic:before {
    background-color: currentColor;
  }
  &.active {
    background-color: var(--lia-divider-bg-color-active);
  }
}

.lia-g-divider-element {
  display: block;
  background-color: var(--lia-divider-bg-color);
  margin: 0 var(--lia-divider-mr) 0 var(--lia-divider-ml);
  height: var(--lia-divider-size);
  width: var(--lia-divider-size);
  border-radius: var(--lia-divider-border-radius);

  &.lia-g-is-color-mimic {
    background-color: currentColor;
  }
}

.lia-g-list-item-pr {
  padding-right: var(--lia-bs-list-group-item-padding-x);
}

.lia-g-list-item-pl {
  padding-left: var(--lia-bs-list-group-item-padding-x);
}

.lia-g-list-item-px {
  padding-right: var(--lia-bs-list-group-item-padding-x);
  padding-left: var(--lia-bs-list-group-item-padding-x);
}

/* Replacement for the standard UL with similar styles. */
.lia-g-better-ul {
  display: table;
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    display: table-row;

    &:before {
      display: table-cell;
      content: '•';
      padding-right: 5px;
    }
  }
}
