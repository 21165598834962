.lia-g-editor-toolbar {
  width: 100%;

  :global {
    .tox-tinymce-inline {
      visibility: inherit !important;
      display: inherit !important;
    }

    .tox-autocompleter {
      display: none !important;
    }

    .tox {
      &.tox-tinymce {
        border-radius: var(--lia-bs-border-radius-sm);
        overflow: hidden;
      }

      &.tox-tinymce--toolbar-bottom .tox-editor-header,
      &.tox-tinymce-inline .tox-editor-header {
        border: 0;
        margin: 0;
      }

      .tox-toolbar,
      .tox-toolbar__overflow,
      .tox-toolbar__primary {
        background: none;
        background-color: var(--lia-bs-gray-200);
        padding: 0;
        margin: 0 -1px;
      }

      .tox-toolbar__group {
        margin: 7px 0;
        padding: 0 4px;
      }

      .tox-toolbar.tox-toolbar--scrolling {
        border-radius: var(--lia-bs-border-radius-sm);

        &:before,
        &:after {
          position: absolute;
          content: '';
          width: 6px;
          top: 0;
          bottom: 0;
          z-index: 4;
        }
        &:before {
          left: 0;
          background: linear-gradient(
            90deg,
            hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.12) 0%,
            hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0) 100%
          );
        }
        &:after {
          right: 0;
          background: linear-gradient(
            90deg,
            hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0) 0%,
            hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.12) 100%
          );
        }

        .tox-toolbar__group {
          &:last-child {
            flex-grow: 1;
          }
          &:first-child:before,
          &:last-child:after {
            display: block;
            content: '';
            height: calc(100% + 14px);
            width: 7px;
            background-color: var(--lia-bs-gray-200);
            z-index: 5;
          }
          &:first-child:before {
            margin-left: -4px;
          }
          &:last-child:after {
            flex-grow: 1;
            margin-right: -4px;
          }
        }
      }

      &:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
        border-color: var(--lia-bs-gray-300);
      }

      .tox-menu {
        margin: 5px 0;
        border: 1px solid var(--lia-bs-border-color);
        border-radius: var(--lia-bs-border-radius-sm);
        box-shadow: var(--lia-bs-box-shadow);
        background-color: var(--lia-bs-white);
      }

      .tox-collection--list {
        .tox-collection__item--active {
          background-color: var(--lia-bs-gray-300);

          &:not(.tox-collection__item--state-disabled) {
            color: inherit;
          }
        }
        .tox-collection__item--enabled {
          background-color: var(--lia-bs-gray-300);
        }
      }

      .tox-tbtn--select,
      .tox-split-button,
      .tox-tbtn {
        margin: 0;
        padding: 0;
      }

      .tox-tbtn {
        color: var(--lia-bs-dark);
        background-color: transparent;

        &:hover,
        &--enabled,
        &--enabled:hover,
        &:focus:not(.tox-tbtn--disabled) {
          color: var(--lia-bs-dark);
          background-color: var(--lia-bs-gray-300);

          svg {
            fill: var(--lia-bs-dark);
          }
        }
        &.tox-tbtn--select {
          min-width: 43px;

          .tox-icon.tox-tbtn__icon-wrap {
            display: flex;
            justify-content: center;
          }
        }
        .tox-icon,
        .tox-tbtn__select-chevron {
          svg > path {
            fill: var(--lia-bs-dark);
          }
        }
        &.tox-tbtn--disabled {
          .tox-icon {
            svg > path {
              fill: var(--lia-bs-btn-disabled-opacity);
            }
          }
        }

        svg {
          fill: var(--lia-bs-dark);
        }
      }

      .tox-tbtn[aria-label='Update'] {
        margin: -7px -4px;
        height: 36px;
        width: 36px;
        box-shadow: var(--lia-box-shadow-xs);
      }

      .tox-pop {
        &:before,
        &:after {
          content: none;
        }
        &.tox-pop--top {
          margin-top: -5px;
        }
        &.tox-pop--bottom {
          margin-bottom: -5px;
        }
      }

      .tox-pop__dialog {
        border-radius: var(--lia-bs-border-radius-sm);
        box-shadow: var(--lia-box-shadow-xs);
        background-color: var(--lia-bs-white);
        border: 0;
        .tox-toolbar {
          margin: 0;
          &__group {
            margin: 0;
            padding: 0;
            .tox-tbtn {
              padding: 1px;
              &:hover,
              &:focus {
                background-color: var(--lia-bs-light);
              }
              &--enabled,
              &--enabled:hover {
                background-color: var(--lia-bs-gray-300);
              }
              &--disabled,
              &--disabled:hover,
              &--disabled:focus {
                background-color: var(--lia-bs-white);
                svg {
                  fill: var(--lia-bs-gray-500);
                }
              }
            }
          }
        }
        .tox-toolbar--scrolling {
          overflow-x: hidden;
          &:before,
          &:after {
            background: none;
            content: none;
          }
          .tox-toolbar__group {
            &:first-child:before,
            &:last-child:after {
              content: none;
            }
          }
        }
      }

      .tox-tbtn__select {
        &-label {
          width: auto;
          margin: 0 0 0 7px;
          color: var(--lia-bs-body-color);
        }
        &-chevron {
          margin: 0 1px;
        }
      }

      .tox-split-button {
        &:hover {
          box-shadow: none;
        }
        &__chevron {
          width: 18px;

          svg > path {
            fill: var(--lia-bs-dark);
          }
        }
      }

      &.tox-tinymce-aux {
        z-index: 1000;
        display: block !important;

        .tox-toolbar__overflow {
          border: 0;
          margin-top: 9px;
          box-shadow: var(--lia-box-shadow-xs);
          border-radius: var(--lia-bs-border-radius-sm);
          background-color: var(--lia-bs-gray-200);
        }
      }

      .tox-collection--list .tox-collection__item {
        padding: 5px 8.5px;
      }

      .tox-collection__item {
        &-label {
          font-size: inherit;
          line-height: inherit;
          white-space: nowrap;
          color: var(--lia-bs-dark);
        }
        &-checkmark svg,
        &-icon svg,
        &-caret svg {
          fill: var(--lia-bs-dark);
        }
      }

      &:not([dir='rtl']) .tox-collection--list .tox-collection__item {
        &-label:first-child {
          margin: 0;
        }
        > :not(:first-child) {
          margin: 0 0 0 8.5px;
        }
      }

      /* TODO: Remove this after modal work has been done. */
      .tox-dialog-wrap__backdrop {
        background-color: hsla(
          var(--lia-bs-black-h),
          var(--lia-bs-black-s),
          var(--lia-bs-black-l),
          0.3
        );
      }

      .tox-dialog {
        border: none;
        background-color: var(--lia-bs-white);
        border-radius: var(--lia-bs-border-radius-lg);
        box-shadow: var(--lia-bs-box-shadow-lg);
        max-width: var(--lia-bs-modal-sm);
      }

      .tox-insert-table-picker__label {
        color: var(--lia-bs-text-muted);
      }
    }
  }
}
