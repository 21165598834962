@import '@aurora/shared-client/styles/_variables.pcss';

/*
 * Default Bootstrap toast styling is designed for use in a stack.
 * To apply properly to a queue, flex-direction column-reverse is applied,
 * and the :not(:last-child) margin style for the toast is reversed.
 */
.lia-container {
  &-banner,
  &-flyout {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    z-index: 9999;
  }

  &-banner {
    align-items: center;
    right: 50%;
    bottom: 25px;
    left: 50%;

    @media (--lia-breakpoint-down-sm) {
      margin: 0 5px;
      right: 0;
      left: 0;
    }
  }

  &-flyout {
    top: 25px;
    right: 25px;

    @media (--lia-breakpoint-down-sm) {
      margin: 0 20px;
      right: 0;
    }
  }
}

.lia-toast + .lia-toast {
  margin-bottom: 10px;
}
