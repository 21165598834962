/**
 * Based on Prism.js default by Lea Verou
 */

.lia-g-message-body {
  :global {
    .lia-code-sample {
      color: var(--lia-prism-color);
      background-color: var(--lia-prism-bg-color);
      font-family: var(--lia-prism-font-family);
      font-size: var(--lia-prism-font-size);
      text-align: left;
      white-space: pre;
      word-spacing: normal;
      word-break: normal;
      word-wrap: normal;
      line-height: var(--lia-bs-line-height-base);
      border: 1px solid var(--lia-bs-border-color);

      -moz-tab-size: var(--lia-prism-tab-size);
      -o-tab-size: var(--lia-prism-tab-size);
      tab-size: var(--lia-prism-tab-size);

      -webkit-hyphens: none;
      -moz-hyphens: none;
      -ms-hyphens: none;
      hyphens: none;

      &::-moz-selection,
      &::selection {
        text-shadow: none;
        background: var(--lia-prism-highlight-color);
      }

      .token.comment,
      .token.prolog,
      .token.doctype,
      .token.cdata {
        color: var(--lia-prism-comment-color);
      }

      .token.punctuation {
        color: var(--lia-prism-punctuation-color);
      }

      .token.namespace {
        opacity: var(--lia-prism-namespace-opacity);
      }

      .token.property,
      .token.tag,
      .token.boolean,
      .token.number,
      .token.constant,
      .token.symbol,
      .token.deleted {
        color: var(--lia-prism-prop-color);
      }

      .token.selector,
      .token.attr-name,
      .token.string,
      .token.char,
      .token.builtin,
      .token.inserted {
        color: var(--lia-prism-selector-color);
      }

      .token.operator,
      .token.entity,
      .token.url,
      &.language-css .token.string,
      &.style .token.string {
        color: var(--lia-prism-operator-color);
        background: var(--lia-prism-operator-bg-color);
      }

      .token.atrule,
      .token.attr-value,
      .token.keyword {
        color: var(--lia-prism-keyword-color);
      }

      .token.function,
      .token.class-name {
        color: var(--lia-prism-function-color);
      }

      .token.regex,
      .token.important,
      .token.variable {
        color: var(--lia-prism-variable-color);
      }

      .token.important,
      .token.bold {
        font-weight: var(--lia-prism-font-weight-bold);
      }

      .token.italic {
        font-style: var(--lia-prism-font-style-italic);
      }

      .token.entity {
        cursor: help;
      }
    }

    /* Code Blocks */
    pre.lia-code-sample {
      margin-top: var(--lia-rte-special-message-item-mt);
      margin-bottom: var(--lia-rte-special-message-item-mb);
      padding: 20px;
      border-radius: var(--lia-bs-border-radius-sm);
      white-space: pre;
      overflow: auto;
      line-height: 1.4;
    }

    /* Inline Code */
    code.lia-code-sample {
      padding: 0.1em;
      border-radius: 0.3em;
      white-space: normal;
    }
  }

  &.lia-g-message-body-forum {
    :global {
      pre.lia-code-sample {
        margin-top: var(--lia-rte-default-message-item-mt);
        margin-bottom: var(--lia-rte-default-message-item-mb);
      }
    }
  }
}
