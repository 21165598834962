:root {
  /* Chart Colors Variants */
  --lia-chart-bias-neutral: hsl(0, 0%, 44%);
  --lia-chart-bias-positive: hsl(150, 83%, 42%);
  --lia-chart-bias-negative: hsl(350, 93%, 52%);

  --lia-chart-contrast-teal: hsl(187, 66%, 49%);
  --lia-chart-contrast-pink: hsl(340, 75%, 59%);
  --lia-chart-contrast-yellow: hsl(54, 87%, 65%);
  --lia-chart-contrast-slate: hsl(200, 18%, 46%);
  --lia-chart-contrast-purple: hsl(291, 46%, 44%);
  --lia-chart-contrast-lime: hsl(68, 65%, 43%);
  --lia-chart-contrast-violet: hsl(258, 58%, 63%);
  --lia-chart-contrast-orange: hsl(15, 87%, 57%);
  --lia-chart-contrast-blue: hsl(205, 100%, 50%);

  --lia-chart-shade-blue-1: hsl(205, 100%, 30%);
  --lia-chart-shade-blue-2: hsl(205, 100%, 40%);
  --lia-chart-shade-blue-3: hsl(205, 100%, 50%);
  --lia-chart-shade-blue-4: hsl(205, 100%, 60%);
  --lia-chart-shade-blue-5: hsl(205, 100%, 70%);
  --lia-chart-shade-blue-6: hsl(205, 100%, 80%);

  --lia-chart-shade-orange-1: hsl(14, 77%, 51%);
  --lia-chart-shade-orange-2: hsl(14, 100%, 60%);
  --lia-chart-shade-orange-3: hsl(18, 87%, 73%);
  --lia-chart-shade-orange-4: hsl(17, 100%, 87%);
  --lia-chart-shade-orange-5: hsl(18, 87%, 94%);

  --lia-page-editor-danger: hsla(347, 70%, 54%, 0.6);
  --lia-page-editor-dragging: hsla(231, 58%, 50%, 0.05);
  --lia-page-editor-primary-h: 231;
  --lia-page-editor-primary-s: 58%;
  --lia-page-editor-primary-l: 50%;
  --lia-page-editor-primary: hsl(
    var(--lia-page-editor-primary-h),
    var(--lia-page-editor-primary-s),
    var(--lia-page-editor-primary-l)
  );
}
