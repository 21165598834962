.lia-popover {
  padding: 10px 15px 15px;

  &-header {
    color: var(--lia-bs-gray-900);
    padding-bottom: 10px;
    border-bottom: 1px solid var(--lia-bs-border-color);
    margin-bottom: 10px;
    font-weight: var(--lia-bs-font-weight-bold);
  }
}
