:root {
  --lia-d-navbar-height: '0';
  --lia-d-navbar-height-sticky: '0';
  --lia-d-header-height: '0';
  --lia-bs-grid-gutter-width: var(--lia-grid-gutter-width-md);

  @media (max-width: 575.98px) {
    --lia-bs-grid-gutter-width: var(--lia-grid-gutter-width-xs);
  }
}

html,
body {
  min-height: 100dvh;
}

#__next {
  display: var(--lia-vars-loaded, none);
  flex-direction: column;
  min-height: 100dvh;

  > main:not([data-main-override]) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  > nav,
  > footer {
    flex-shrink: 0;
  }
}

html > body {
  margin: var(--lia-body-margin);
  max-width: var(--lia-body-max-width);
}

*:focus-visible:not(button):not(input) {
  outline: var(--lia-outline-color) solid 3px;
  outline-offset: 0;
}

:target {
  scroll-margin-top: var(--lia-d-navbar-height-sticky);
}
