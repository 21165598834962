@import '@aurora/shared-client/styles/_variables.pcss';
@import '../mixins/_spacers.pcss';

.lia-g-list-li-mb {
  &-none,
  &-sm,
  &-md,
  &-lg,
  &-xl,
  &-xxl {
    margin-bottom: 0;
  }

  &-none > li:not(:last-of-type) {
    margin-bottom: 0;
  }
  &-sm > li:not(:last-of-type) {
    margin-bottom: var(--lia-list-li-mb-sm);
  }
  &-md > li:not(:last-of-type) {
    margin-bottom: var(--lia-list-li-mb-md);
  }
  &-lg > li:not(:last-of-type) {
    margin-bottom: var(--lia-list-li-mb-lg);
  }
  &-xl > li:not(:last-of-type) {
    margin-bottom: var(--lia-list-li-mb-xl);
  }
  &-xxl > li:not(:last-of-type) {
    margin-bottom: var(--lia-list-li-mb-xxl);
  }
}

@each $breakpoint in (null, -sm, -md, -lg, -xl) {
  @if $breakpoint == null {
    @include createSpacingRulesForBreakpoint();
  } @else {
    @media (--lia-breakpoint-up$(breakpoint)) {
      @include createSpacingRulesForBreakpoint($breakpoint);
    }
  }
}

@each $size in (5, 10, 15, 20, 25) {
  .lia-g-gap-$(size) {
    /* prettier-ignore */
    gap: $(size)px !important;
  }
  .lia-g-column-gap-$(size) {
    /* prettier-ignore */
    column-gap: $(size)px !important;
  }
  .lia-g-row-gap-$(size) {
    /* prettier-ignore */
    row-gap: $(size)px !important;
  }
}
