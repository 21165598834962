.lia-g-count-wrap {
  display: inline-flex;
  align-items: center;
  color: var(--lia-bs-text-muted);
  font-weight: var(--lia-font-weight-md);
  font-size: var(--lia-bs-font-size-sm);
  user-select: none;

  &:not(.lia-g-count-use-text) {
    gap: 5px;
  }
}

.lia-g-count-text + .lia-g-count-number:before,
.lia-g-count-number + .lia-g-count-text:before {
  content: ' ';
  white-space: pre;
}
