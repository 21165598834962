.lia-g-is-number {
  font-variant-numeric: tabular-nums;
}

.lia-g-text {
  &-lg {
    font-size: var(--lia-font-size-xl);
  }
  &-md {
    font-size: var(--lia-bs-font-size-base);
  }
  &-sm {
    font-size: var(--lia-bs-font-size-sm);
    letter-spacing: var(--lia-letter-spacing-sm);
  }
  &-xs {
    font-size: var(--lia-font-size-xs);
    letter-spacing: var(--lia-letter-spacing-xs);
  }
  &-xxs {
    font-size: var(--lia-font-size-xxs);
  }
  &-black {
    color: var(--lia-bs-black) !important;
  }
  &-link {
    color: var(--lia-bs-link-color) !important;
  }
}

.lia-g-link {
  color: var(--lia-bs-link-color);
  text-decoration: var(--lia-bs-link-decoration);

  &:hover {
    color: var(--lia-bs-link-hover-color);
    text-decoration: var(--lia-bs-link-hover-decoration);
  }
}

.lia-g-subheading {
  letter-spacing: var(--lia-letter-spacing-subheading);
  font-size: var(--lia-subheader-font-size);
  font-weight: var(--lia-subheader-font-weight);
  text-transform: uppercase;
}

.lia-g-underline {
  text-decoration: underline;
}

.lia-g-code-block {
  background-color: var(--lia-bs-gray-200);
  padding: 20px;
  font-family: var(--font-family-monospace);
  font-size: var(--lia-bs-font-size-sm);
  line-height: 1.6;
  max-width: 100%;
  overflow: auto;
  word-wrap: break-word;
  border: 1px solid var(--lia-bs-border-color);
  border-radius: var(--lia-bs-border-radius-sm);
  margin: 0;
}

.lia-g-body-text {
  font-family: var(--lia-bs-font-family-base);
  font-size: var(--lia-bs-font-size-base);
  font-weight: var(--lia-bs-font-weight-base);
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-bs-body-color);
}

.lia-g-font-weight-medium {
  font-weight: var(--lia-font-weight-md);
}
